<template>
	<div>
		<div class="icon-rotate-wrapper">
			<div class="icon-rotate"></div>
		</div>
	</div>
</template>

<script>
export default {

  name: 'icon-rotate',

  data () {
    return {

    }
  }
}
</script>

<style lang="less" scoped>
	.icon-rotate-wrapper{
		position:relative;
	}
	.icon-rotate{
		position: absolute;
		left: -10px;
		top:-10px;
		width: 20px;
		height: 20px;
		border:4px solid green;
		border-top-color:rgba(255,255,255,0.5);
		border-left-color:rgba(255,255,255,0.5);
		border-right-color:rgba(255,255,255,0.5);
		border-radius:50%;
		animation: rotate 1s infinite linear;
		
	}
	@keyframes rotate{
		0%{
			transform:rotate(0);
		}

		100%{
			transform:rotate(360deg);
		}
	}
</style>