<template>
	<div class="btn-up">
		<img class="icon-camera" src="./assets/upload_img_icon.png" alt="">
		<!-- <p class="tip">上传图片</p> -->
		<input :id="id" type="file" name="file" accept="image/*" multiple v-on="$listeners">
	</div>	


	
</template>

<script>
export default {

  name: 'btn-up',

  data () {
    return {

    }
  },
  props:{
	  id:{
		  type:String,
		  default:''
	  }
  }
}
</script>

<style lang="less" scoped>
.btn-up{
	position: relative;
	border:1px solid #000;
	border-radius:0px;
	background-color: #FFF;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 70px;
	height: 70px;
	max-width: 70px;


	flex:1 70px;
	input[type="file"]{
		position:absolute;
		left: 0;
		top:0;
		width:100%;
		height: 100%;
		opacity: 0;
		z-index: 1;
	}
}
.icon-camera{
	width: 20px;
	height: 20px;
}
.tip{
	margin: 0;
	margin-top: 7px;
	padding: 0;
	font-size: 12px;
	color: #AAAAAA;
}
</style>