<template>
    <div class="uploader">
        <san
            v-for="(vo,index) in voArr"
            :key="index"
            v-bind="vo"
            @removeimg="removeImg(vo)"
            @changestatus="changeStatus(vo,$event)"
            @onebegin="oneBegin()"
            @showbigimg="showBigImg(vo)"
        ></san>
        <btnUp
            v-bind="$attrs"
            v-bind:id="id"
            v-show="maxNum!=voArr.length"
            @change="selectImgs"
        ></btnUp>
    </div>
</template>

<script>
import san from "./san";
import btnUp from "./btn-up";
import imagePreview from "./qsc-imagepreview";

const CHANGEBEGIN = "changebegin";
const CHANGEEND = "changeend";

var fileInput;

export default {
    name: "default",
    inheritAttrs: false,

    data() {
        return {
            voArr: []
        };
    },

    props: {
        maxNum: {
            type: Number,
            default: 8
        },
        imgArr: {
            type: Array,
            default: () => []
        },
        id: {
            type: String,
            default: ""
        }
    },

    methods: {
        selectImgs(e) {
            fileInput = e.currentTarget;
            var files = e.currentTarget.files;
            this.selectNum = files.length;
            var willNum = files.length + this.voArr.length;
            if (willNum > this.maxNum) {
                alertMsg("最多只能上传" + this.maxNum + "张图片");
                return;
            }

            for (var i = 0; i < files.length; i++) {
                var vo = {
                    status: 2,
                    url: "",
                    progress: 0,
                    fileObj: files[i]
                };
                this.voArr.push(vo);
            }

            this.$emit(CHANGEBEGIN);
        },

        removeImg(vo) {
            var ind = this.voArr.indexOf(vo);
            this.voArr.splice(ind, 1);

            var dataArr = this.voArr.map(o => o.url);
            // console.log('change by remove')
            this.$emit(CHANGEEND, dataArr);
            // console.log(dataArr)
        },
        showBigImg(vo) {
            var ind = this.voArr.indexOf(vo);
            // console.log(ind,this.voArr)

            var bigImages = this.voArr.map(o => o.url);

            imagePreview({
                current: ind,
                images: bigImages
            });
        },
        changeStatus(vo, evtObj) {
            vo.status = evtObj.status;

            if (evtObj.status == 0) {
                vo.url = evtObj.url;
                vo.progress = 100;
            } else if (evtObj.status == 1) {
                vo.url = "";
                vo.progress = 0;
                vo.fileObj = "";
            } else if (evtObj.status == 2) {
                vo.url = "";
                vo.progress = evtObj.progress;
                if (evtObj.fileObj) {
                    vo.fileObj = evtObj.fileObj;
                }
            }

            var status0Arr = this.voArr.filter(o => o.status == 0);
            var status1Arr = this.voArr.filter(o => o.status == 1);
            // console.log('status0Arr.length+status1Arr.length == this.voArr.length',status0Arr.length,status1Arr.length ,this.voArr.length)
            if (status0Arr.length + status1Arr.length == this.voArr.length) {
                var dataArr = this.voArr.map(o => o.url);
                // console.log('change by all or onechange',dataArr)
                fileInput.value = "";
                this.$emit(CHANGEEND, dataArr);
            }
        },
        oneBegin() {
            this.$emit(CHANGEBEGIN);
        }
    },
    components: {
        san,
        btnUp
    },
    created() {
        // alert(11)
        var that = this;
        this.imgArr.forEach(function(item, index) {
            var vo;
            if (item) {
                vo = {
                    status: 0,
                    url: item,
                    progress: 100
                };
            } else {
                vo = {
                    status: 1,
                    url: "",
                    progress: 0
                };
            }
            // vo.myname ='lidong'+index;
            that.voArr.push(vo);
        });

        // console.log(this.maxNum,this.voArr.length)
    }
};
</script>

<style lang="less" scoped>
.uploader {
    display: flex;
    flex-wrap: wrap;
}
</style>
