import Vue from 'vue';
import Preview from './preview.vue';

const ua = navigator.userAgent.toLowerCase();
const isWeixin = ua.indexOf('micromessenger') !== -1;

let instance;

/**
 * 预览图片
 * @param {Object} options
 * @param {Number} options.current
 * @param {Array.<String>} options.images
 */
export default function (options) {
    if (!options) return;
    const { current = 0, images = [] } = options;

    if (images.length <= 0) return;

    if (isWeixin && window.wx && window.wx.previewImage) {
        const currentImage = images[current];
        const urls = images;
        wx.previewImage({
            current: currentImage,
            urls
        });
        return;
    }

    previewInNonWeixinEnvironment(options);
}

const initInstance = () => {
    instance = new (Vue.extend(Preview))({
        el: document.createElement('div')
    });

    document.body.appendChild(instance.$el);
}

/**
 * 在非微信环境下预览图片，简单弹出全屏图片
 *
 * @param {object} options
 * @param {Number} options.current
 * @param {Array.<String>} options.images
 */
function previewInNonWeixinEnvironment(options) {
    if (!options) return;

    const { current, images } = options;
    if (!images || images.length <= 0) return;

    if (current < 0 || current >= images.length) return;

    const image = images[current];

    if (!instance) {
        initInstance();
    }

    instance.show = true;
    instance.image = image;

    instance.$once('close', () => {
        instance.show = false;
    });
}
