<template>
	<div class="bg">
		<div class="inner" :style="{width: comWidth}"></div>
    <div class="tip">上传中{{comWidth}}</div>
	</div>
</template>

<script>

  
export default {

  name: 'tips-progress',

  data () {
    return {

    }
  },
  props:{
  	value:{
  		type: Number,
  		default:0
  	}
  },
  computed:{
    comWidth(){
      return this.value+'%'
    }
  }
}
</script>

<style lang="less" scoped>
.bg{
	background-color: rgba(0,0,0,0.4);
	height: 14px;
  width: 100%;
}
.inner{
	height: 100%;
	background-color:#55C355;
}
.tip{
  position:absolute;
  width: 100%;
  white-space:nowrap;
  height: 14px;
  padding:1px 0;
  margin: 0;
  left: 0;
  top:0;
  text-align: center;
  font-size: 12px;
  line-height: 12px;
  color:#FFF;
}
</style>